@import './variables.scss';

@mixin flexbox($jc: center, $ai: center, $fd: row) {
  display: flex;
  justify-content: $jc;
  align-items: $ai;
  flex-direction: $fd;
}

@mixin font(
  $size: $font_size_base,
  $color: $font_color_base,
  $weight: $font_weight_base,
  $line-height: $font_base_line_height,
  $style: $font_base_style,
  $family: $font_base_family,
) {
  font-size: $size;
  color: $color;
  font-weight: $weight;
  line-height: $line-height;
  font-style: $style;
  font-family: $family;
}

@mixin for-size($range) {
  $mobile: 320px;
  $tablet: 768px;
  $desktop: 1280px;

  @if $range == mobile-only {
    @media (max-width: #{$mobile}) {
      @content;
    }
  } @else if $range == mobile {
    @media (min-width: $mobile) {
      @content;
    }
  } @else if $range == tablet-only {
    @media (max-width: #{$tablet - 1}) {
      @content;
    }
  } @else if $range == tablet {
    @media (min-width: $tablet) {
      @content;
    }
  } @else if $range == desktop-only {
    @media (max-width: #{$desktop - 1}) {
      @content;
    }
  } @else if $range == desktop {
    @media (min-width: $desktop) {
      @content;
    }
  }
}

@mixin for-pixel-ratio ($ratio: 2) {
  @if $ratio == 2 {
    @media screen and (min-device-pixel-ratio: 2),
    screen and (-webkit-min-device-pixel-ratio: 2),
    screen and (-o-min-device-pixel-ratio: 2/1),
    screen and (min-resolution: 192dpi),
    screen and (min-resolution: 2dppx) {
      @content;
    }
  } @else if $ratio == 3 {
    @media screen and (min-device-pixel-ratio: 3),
    screen and (-webkit-min-device-pixel-ratio: 3),
    screen and (-o-min-device-pixel-ratio: 3/1),
    screen and (min-resolution: 288dpi),
    screen and (min-resolution: 3dppx) {
      @content;
    }
  }
}